.candlesRow {
  width: 100%;
  max-width: 500px;
  margin-top: 10px;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: var(--gutter-compensation, -0.5rem);
  margin-left: var(--gutter-compensation, -0.5rem);
  justify-content: center;
  text-align: center;
}

.candleCol {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: var(--half-gutter-width, 0.5rem);
  padding-left: var(--half-gutter-width, 0.5rem);
  flex-grow: 1;
  flex-basis: 0;
  // max-width: 100%;
  flex-basis: 10%;
  width: 10%;
  max-width: 50px;
}

.candlesMessage {
  margin-top: 10px;
}
