$fsize: max(5vw, 36pt); //vmin;
$lheight: calc($fsize * 1.1);
$ffamily: Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;

.lead {
    font-family: $ffamily;
    font-size: $fsize;
    font-weight: bold;
    line-height: $lheight;
    margin: 0;
    padding: 0;
    animation: lead 0.3s;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

// .word {
//     padding-left: min(13pt, 2vw);
// }

.word:not(:first-child) {
    padding-left: min(13pt, 2vw);
}

@keyframes lead {
    from {
        font-weight: bold;
        opacity: 0.4;
        transform: translate3d(0, $lheight, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.next {
    font-family: $ffamily;
    font-size: $fsize;
    line-height: $lheight;
    margin: 0;
    padding: 0;
    opacity: 0.4;
    animation: next 0.3s;
}

@keyframes next {
    from {
        opacity: 0;
    }
  
    to {
        opacity: 0.4;
    }
}

.animate {
    // #f0ee9e
    animation: fill linear both;
    background: linear-gradient(to right, #fdfa57 50%, #fff 50%);
    background-size: 200% auto;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

@keyframes fill {
    0% {
        background-position: 100% 0;
    }

    100% {
        background-position: 0 0;
    }
}