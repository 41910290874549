.paywall {
  border: 2px solid #efff0c;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  max-height: 100%;
  overflow-y: auto;
  margin-top: 10px;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  // flex-wrap: wrap;
  margin-right: var(--gutter-compensation, -0.5rem);
  margin-left: var(--gutter-compensation, -0.5rem);
  // justify-content: center;
  text-align: center;
}

.pw-head {
  font-size: max(4vw, 30pt);
  font-weight: bold;
  margin-bottom: 30px;
}

.pw-text {
  font-size: max(1vw, 20pt);
  margin-bottom: 30px;
}

button {
  font-family: inherit; /* For all browsers */
  font-size: 100%; /* For all browsers */
  line-height: 1.15; /* For all browsers */
  margin: 0; /* Firefox and Safari have margin */
  overflow: visible; /* Edge hides overflow */
  text-transform: none; /* Firefox inherits text-transform */
  -webkit-appearance: button; /* Safari otherwise prevents some styles */
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.pw-cta {
  width: 100%;
  max-width: 250px;
  color: rgb(90, 31, 129) !important;
  // color: rgb(41, 66, 255) !important;
  font-size: max(1vw, 20pt);
  font-weight: bold;
  // text-transform: uppercase;
  text-decoration: none;
  background: #ffd61f;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 50px;
  display: inline-block;
  border: none;
  transition: all 0.4s ease 0s;

  &:hover {
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    transition: all 0.4s ease 0s;
  }
}

.pw-decline {
  // text-decoration: none;
  color: #a8bdca !important;

  background: none !important;
  border: none;
  padding: 0 !important;
  /*input has OS specific font-family*/
  text-decoration: underline;
  cursor: pointer;
}
