.candleShowAnimation {
  animation-name: showUp;
  animation-duration: 2s;
}

@keyframes showUp {
  0%   {opacity: 0;}
  100% {opacity: 1;}
}

// .fire {
//   // margin: 80px auto;
//   width: 60px;
//   height: 60px;
//   position: absolute;
//   transform-origin:center bottom;
//   animation-name: flicker;
//   animation-duration:3ms;
//   animation-delay:200ms;
//   animation-timing-function: ease-in;
//   animation-iteration-count: infinite;
//   animation-direction: alternate;
// }

// .flame{
//   bottom:0;
//   position:absolute;
//   border-bottom-right-radius: 50%;
//   border-bottom-left-radius: 50%;
//   border-top-left-radius: 50%;
//   transform:rotate(-45deg) scale(1.5,1.5);
// }

// .yellow{
//   left:15px; 
//   width: 30px;
//   height: 30px;
//   background:gold;
//   box-shadow: 0px 0px 9px 4px gold;
// }

// .orange{
//   left:10px; 
//   width: 40px;
//   height: 40px;
//   background:orange;
//   box-shadow: 0px 0px 9px 4px orange;
// }

// .red{
//   left:5px;
//   width: 50px;
//   height: 50px;
//   background:OrangeRed;
//   box-shadow: 0px 0px 5px 4px OrangeRed;
// }

// .white{
//   left:15px; 
//   bottom:-4px;
//   width: 30px;
//   height: 30px;
//   background:white;
//   box-shadow: 0px 0px 9px 4px white;
// }

// .circle{
//   border-radius: 50%;
//   position:absolute;  
// }

// .blue{
//   width: 10px;
//   height: 10px;
//   left:25px;
//   bottom:-25px; 
//   background: SlateBlue;
//   box-shadow: 0px 0px 15px 10px SlateBlue;
// }

// .black{
//   width: 40px;
//   height: 40px;
//   left:10px;
//   bottom:-60px;  
//   background: black;
//   box-shadow: 0px 0px 15px 10px black;
// }

// @keyframes flicker{
//   0%   {transform: rotate(-1deg);}
//   20%  {transform: rotate(1deg);}
//   40%  {transform: rotate(-1deg);}
//   60%  {transform: rotate(1deg) scaleY(1.04);}
//   80%  {transform: rotate(-2deg) scaleY(0.92);}
//   100% {transform: rotate(1deg);}
// }

// .fire {
//   width: 50px;
//   height: 30px;
//   border: 5px solid #f3ac3c;
//   border-radius: 0 60% / 0 100%;
//   transform: rotate(90deg);
//   z-index: 20;
//   position: absolute;
// }



.fire {
	position: absolute;
	z-index: 39;
	width: 2px;
	margin-left: -1px;	
	// left: 50%; 
	// bottom: 80px;
	-webkit-transition: all 1200ms linear;
	transition: all 1200ms linear; 
}
.fire span { 
	display: block;
	position: absolute;
	bottom: -15px; 
	margin-left: -20px;
	height: 0px; width: 0px;
	border: 30px solid #febd08;
	border-radius: 50%;
	border-top-left-radius: 0;
	left: -9px; 
	box-shadow: 
		0 0 10px 5px rgba(244,110,28,0.8),
		0 0 20px 10px rgba(244,110,28,0.6),
		0 0 30px 15px rgba(244,110,28,0.3);
	transform: scale(0.45, 0.75) rotate(45deg);
	animation: brilla-fire 2.5s alternate infinite;
	z-index: 9;
	-webkit-transition: all 1200ms linear;
	transition: all 1200ms linear; 
}
.fire span:after { 
	display: block;
	position: absolute;
	bottom: -30px; 
	content: '';
	margin-left: -5px;
	height: 30px; 
	width: 12px;
	background-color: rgba(244,110,28,0.7);
	border-radius: 80px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	box-shadow: 
		0 0 20px 10px rgba(244,110,28,0.7);
	left: -9px; 
	opacity: 0.8;
	transform: rotate(-50deg);
}
.fire span:nth-child(2) { 
	left: -22px; 
	transform: scale(0.3, 0.55) rotate(15deg);
	z-index: 8;
	animation: brilla-fire 1.5s alternate infinite;
}
.fire span:nth-child(3) { 
	left: 4px; 
	transform: scale(0.3, 0.55) rotate(80deg);
	z-index: 8;
	animation: brilla-fire 2s alternate infinite;
}
@keyframes brilla-fire{
	0%, 100%{
	box-shadow: 
		0 0 10px 5px rgba(244,110,28,0.8),
		0 0 20px 10px rgba(244,110,28,0.6),
		0 0 30px 15px rgba(244,110,28,0.3);
	}
	50%{
	box-shadow: 
		0 0 14px 7px rgba(244,110,28,0.8),
		0 0 28px 14px rgba(244,110,28,0.6),
		0 0 42px 21px rgba(244,110,28,0.3);
	}
}