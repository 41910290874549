.container {
  text-align: center;

  background-color: #150a08; //#282c34; // так же надо поставить в index.html тег theme-color

  position: fixed;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: white;
}

.header {
  padding: 10px;

  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main {
  padding: 10px;

  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer {
  padding: 10px;

  flex-shrink: 0;

  animation: shake 0.3s;
}

@keyframes shake {
  25% {
    transform: scale(0.97);
  }
  75% {
    transform: scale(1.01);
  }
}
